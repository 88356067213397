// App.js
import React from "react"
import {AuthProvider, useAuth} from "./contexts/AuthContext";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom"
import Home from "./components/Home"
import PrivateRoute from "./components/PrivateRoute"
import SignIn from "./components/SignIn";
import Settings from "./components/Settings/Settings";
function Logout() {
  const {logout} = useAuth()
  return (logout().then(() => {
    window.location.href = "/login"
  }));
}

const AppRoutes = () => {
  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <>
      <Routes location={background || location}>
        <Route path="/login" element={<SignIn />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<Home />} />
        </Route>
        <Route path="/logout" element={<Logout />} />
      </Routes>

      {/* Show modal when we have a background location */}
      {background && (
        <Routes >
          <Route path="/settings" element={<Settings />}>
            <Route path="account" element={<Settings />} />
            <Route path="general" element={<Settings />} />
            <Route path="integration" element={<Settings />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

const App = () => {
  return (
    <div className='App'>
      <Router>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
