// Settings.js
import {Box, List, ListItemIcon, Modal, Typography} from "@material-ui/core";
import React, {lazy, Suspense, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useClickOutside from "../../hooks/useClickOutside";
import {ReactComponent as ReactLogout} from '../../assets/logout.svg';

import {
  AutoAwesomeMosaicOutlined,
  PersonOutlineOutlined as Person,
  SettingsOutlined as SettingsIcon
} from "@mui/icons-material";
import {useAuth} from "../../contexts/AuthContext";
import {Loader} from "lucide-react";

const Integration = lazy(() => import("./Integration"));
const General = lazy(() => import("./General"));
const Account = lazy(() => import("./Account"));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "65%",
  height: "65%",
  display: "flex",
  justifyContent: "flex-start",
  borderRadius: "12px",
  bgcolor: 'background.paper',
  '&:focus': {
    outline: 0
  },
  p: 2,
  boxShadow: "0 4px 48px 0 rgba(0, 0, 0, 0.2)"
};

const settingsItems = [
  {
    name: "Account",
    icon: <Person fontSize={"medium"}/>,
    href: "/settings/account",
    component: <Suspense fallback={<Loader className="w-4 h-4 animate-spin" />}><Account/></Suspense>
  }, {
    name: "General",
    icon: <SettingsIcon fontSize={"small"}/>,
    href: "/settings/general",
    component: <Suspense fallback={<Loader className="w-4 h-4 animate-spin" />}><General/></Suspense>
  },
  {
    name: "Integration",
    icon: <AutoAwesomeMosaicOutlined fontSize={"small"}/>,
    href: "/settings/integration",
    component: <Suspense fallback={<Loader className="w-4 h-4 animate-spin" />}><Integration/></Suspense>
  },
];

const Settings = () => {
  const rootRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const {logout} = useAuth()

  const handleClose = () => {
    navigate('/', {state: {background: null}});
  };

  function logoutOnClick(e) {
    e.preventDefault()
    logout()
  }

  useClickOutside(rootRef, handleClose);

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    const index = settingsItems.findIndex(item => item.href.includes(path));
    setSelected(index >= 0 ? index : 0);
  }, [location]);

  function itemClicked(event, index) {
    setSelected(index);
    navigate(settingsItems[index].href, {
      state: {background: location.state?.background}
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} ref={rootRef}>
        <div className="modal-content">
          <div className={"modal-nav"}>
            <Typography className="modal-nav__title" variant="h5" component="h2">
              Settings
            </Typography>
          </div>
          <Box sx={{
            width: "100%", justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}>
            <List>
              <div>
                {settingsItems.map((item, index) => (
                  <a
                    key={item.name}
                    className={"modal-nav__item"}
                    onClick={(event) => itemClicked(event, index)}
                    style={selected === index ? {backgroundColor: "#e6e6e6"} : {}}
                  >
                    <ListItemIcon className={"modal-nav__icon"}>
                      {item.icon}
                    </ListItemIcon>
                    <span>{item.name}</span>
                  </a>
                ))}
              </div>
            </List>
            <div style={{}}>
              <div className='modal-nav__item ' onClick={logoutOnClick}>

                <ReactLogout className="modal-nav__icon logout"/>
                <span className={"logout"}>Log out</span>

              </div>
            </div>
          </Box>
        </div>
        <div className="model-content_details">
          {settingsItems[selected].component}
        </div>
      </Box>

    </Modal>
  );
};

export default Settings;
