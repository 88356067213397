import {useAuth} from "../contexts/AuthContext";
import React, {useState, useRef, useEffect} from "react";
import {ReactComponent as ReactSettings} from '../assets/settings.svg';
import {ReactComponent as ReactLogout} from '../assets/logout.svg';
// import Avatar from 'react-avatar';
import {useNavigate} from "react-router-dom";
import {Avatar} from "@mui/material";

const Profile = () => {
  const [card, setCard] = useState(false)
  const {currentUser, logout} = useAuth()
  const wrapperRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setCard(false)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function showCard() {
    console.log(card)
    if (card) {
      setCard(false)
    } else setCard(true)
  }

  function logoutOnClick(e) {
    e.preventDefault()
    logout()
  }

  function settingsClick() {
    console.log(location)
    navigate('/settings', {state: {background: {
          pathname: '/',
          search: '',
          hash: '',
          state: null,
          key: 'default'
        }}});

  }
  return (

    <div>
      <div className='profile' onClick={showCard}>
        <Avatar name={currentUser.displayName} size="35" round={true} color="#fff" fgColor="#000"
                style={{border: '1px solid #b07d01', overflow: "hidden", display: "flex"}}
                src={currentUser.photoURL}
                className={"profile-icon-f shadow"}/>
      </div>

      {card && <div ref={wrapperRef} className='card'>
        <div className='card-content'>
          <a>
            <div className='card-header'>

              <Avatar
                      src={currentUser.photoURL}
                      size="50" round={true} color="#fff" fgColor="#000"
                      style={{
                        border: "2px solid #b07d01",
                        overflow: "hidden",
                        display: "flex"
                      }}
                      className=" shadow"/>
              <div className={"card-header-text"}>
                <strong className={"name"}>{currentUser.displayName}</strong>
                <p className={"email"}>{currentUser.email}</p>
              </div>
            </div>

            <div className='card-body'>
              <a className='card-row' onClick={settingsClick}>

                <ReactSettings className="row-icon"/>
                <span>Settings</span>

              </a>
              <div className='card-row' onClick={logoutOnClick}>

                <ReactLogout className="row-icon"/>
                <span>Log out</span>

              </div>
            </div>
          </a>
        </div>
      </div>}
    </div>);
};

export default Profile;
