import React from 'react';
import App from './App';
import {createRoot} from "react-dom/client";
import './App.scss';
// import './App.css';
// import './index.css'

createRoot(document.getElementById('root')).render(
  <App/>
);
