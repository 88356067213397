import React, { useState, useEffect } from 'react';
import { Download, Play, CheckCircle, Loader } from 'lucide-react';
import {useAuth} from "../contexts/AuthContext";

const OnboardingPopup = ({ onClose }) => {
  const {currentUser} = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsCompleted, setStepsCompleted] = useState({
    0: false, // Extension installed
    1: false, // Videos indexed
    2: true,  // Final step always complete
  });
  const [isChecking, setIsChecking] = useState(false);

  const extensionUrl = "https://chromewebstore.google.com/detail/yt-engine/ljfdndnldondabejpidaclepaedoodel";

  // Check if extension is installed
  const checkExtension = () => {
    setIsChecking(true);
    return new Promise((resolve) => {
      window.postMessage({ type: "YT_ENGINE_CHECK" }, "*");

      const timeoutId = setTimeout(() => {
        setIsChecking(false);
        resolve(false);
      }, 1000);

      const messageListener = (event) => {
        if (event.data.type === "YT_ENGINE_RESPONSE") {
          clearTimeout(timeoutId);
          setIsChecking(false);
          resolve(true);
        }
      };

      window.addEventListener("message", messageListener);
      setTimeout(() => {
        window.removeEventListener("message", messageListener);
      }, 1000);
    });
  };

  // Check video indexing status
  const checkIndexingStatus = () => {
    setIsChecking(true);
    if (!currentUser){
      setIsChecking(false);
      return false;
    }
    if (currentUser['watchedVideos'] > 0) {
      setIsChecking(false);
      return true;
    }

  };

  const validateCurrentStep = async () => {
    switch (currentStep) {
      case 0:
        const isInstalled = await checkExtension();
        setStepsCompleted(prev => ({ ...prev, 0: isInstalled }));
        return isInstalled;
      case 1:
        const isIndexed = await checkIndexingStatus();
        setStepsCompleted(prev => ({ ...prev, 1: isIndexed }));
        return isIndexed;
      default:
        return true;
    }
  };

  // Periodically check current step validation
  useEffect(() => {
    const interval = setInterval(async () => {
      if (!stepsCompleted[currentStep]) {
        await validateCurrentStep();
      }
    }, 500);

    return () => clearInterval(interval);
  }, [currentStep, stepsCompleted]);

  const steps = [
    {
      title: "Download the Extension",
      description: "Install YT-Engine extension from the Chrome Web Store",
      icon: <Download className="w-12 h-12 text-blue-500" />,
      action: (
        <div className="mt-6 flex flex-col items-center">
          <a
            href={extensionUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="group relative inline-flex items-center justify-center gap-2 px-8 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg hover:from-blue-600 hover:to-blue-700 transition-all duration-200 shadow-lg hover:shadow-xl"
          >
            <Download className="w-5 h-5" />
            <span className="font-semibold">Get YT-Engine Extension</span>
            <span className="absolute -right-2 -top-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
              Free
            </span>
          </a>
          <p className="mt-3 text-sm text-gray-500">
            Available for Chrome browsers
          </p>
          {isChecking && (
            <div className="flex items-center gap-2 mt-4 text-blue-500">
              <Loader className="w-4 h-4 animate-spin" />
              <span>Checking installation...</span>
            </div>
          )}
          {!isChecking && !stepsCompleted[0] && (
            <p className="text-orange-500 mt-4">Extension not detected. Please install to continue.</p>
          )}
          {!isChecking && stepsCompleted[0] && (
            <p className="text-green-500 mt-4 flex items-center gap-2">
              <CheckCircle className="w-4 h-4" />
              Extension installed successfully!
            </p>
          )}
        </div>
      )
    },
    {
      title: "Index Your Videos",
      description: "Let YT-Engine analyze and index your video content",
      icon: <Play className="w-12 h-12 text-red-500" />,
      action: (
        <div className="mt-6 flex flex-col items-center">
          {isChecking && (
            <div className="flex items-center gap-2 mt-4 text-blue-500">
              <Loader className="w-4 h-4 animate-spin" />
              <span>Checking indexing status...</span>
              <span className="text-xs">({currentUser['watchedVideos']})</span>
            </div>
          )}
          {!isChecking && !stepsCompleted[1] && (
            <p className="text-orange-500 mt-4">Waiting for video indexing to complete...</p>
          )}
          {!isChecking && stepsCompleted[1] && (
            <p className="text-green-500 mt-4 flex items-center gap-2">
              <CheckCircle className="w-4 h-4" />
              Videos indexed successfully!
            </p>
          )}
        </div>
      )
    },
    {
      title: "You're All Set!",
      description: "Start exploring and managing your videos",
      icon: <CheckCircle className="w-12 h-12 text-green-500" />,
    }
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Welcome to YT-Engine</h2>
          <p className="text-gray-600">Follow these steps to get started</p>
        </div>

        <div className="flex mb-8">
          {steps.map((_, index) => (
            <div key={index} className="flex-1 relative">
              <div className={`h-2 ${index === 0 ? 'rounded-l' : index === steps.length - 1 ? 'rounded-r' : ''}
                ${index <= currentStep ? 'bg-blue-500' : 'bg-gray-200'}`} />
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center mb-8">
          {steps[currentStep].icon}
          <h3 className="text-xl font-semibold mt-4 mb-2">{steps[currentStep].title}</h3>
          <p className="text-gray-600 text-center">{steps[currentStep].description}</p>
          {steps[currentStep].action}
        </div>

        <div className="flex justify-between">
          <button
            onClick={() => currentStep > 0 && setCurrentStep(currentStep - 1)}
            className={`px-4 py-2 rounded ${currentStep === 0 ? 'invisible' : 'bg-gray-100 hover:bg-gray-200'}`}
          >
            Back
          </button>

          {currentStep < steps.length - 1 ? (
            <button
              onClick={() => stepsCompleted[currentStep] && setCurrentStep(currentStep + 1)}
              className={`px-4 py-2 rounded ${
                stepsCompleted[currentStep]
                  ? 'bg-blue-500 text-white hover:bg-blue-600'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              Next
            </button>
          ) : (
            <button
              onClick={onClose}
              className="px-4 py-2 bg-[#266DB6] text-white rounded hover:bg-[#1E5590] border-[#1E5590] border transition-colors duration-200"
            >
              Get Started
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingPopup;
